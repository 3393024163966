import React from "react"
import "@mantine/dates/styles.layer.css"
import { DateInput, DateInputProps } from "@mantine/dates"
import { WProps, wProps } from "./WHelpers"
import { DateTime } from "@shared/dates"

// import dayjs from "dayjs"
// import customParseFormat from "dayjs/plugin/customParseFormat"
// dayjs.extend(customParseFormat)

// /** Takes in a Unix epoch (UTC) date value and returns a JS Date. */
// export function utcSecondsToJSDate(date: number | null | undefined) {
//     if (!date) return undefined
//     const { year, month, day } = new DateTime(date)
//     const value = new Date(year, month - 1, day)
//     return value
// }

// /** Takes in a JS Date and returns the Unix epoch (UTC) date value. */
// export function jsDateToUtcSeconds(date: Date | null | undefined) {
//     if (!date) return undefined
//     const value = new DateTime(
//         new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
//     ).toSeconds()
//     return value
// }

type WDateInputProps = WProps<Omit<DateInputProps, "value" | "onChange">> & {
    /** The Unix epoch (UTC) date value. */
    value: number | null | undefined
    /**
     * Callback when the date value changes.
     *
     * @param date - The Unix epoch (UTC) date value.
     */
    onChange?: (date: number | undefined) => void
}

/**
 * A wrapper around DateInput.
 *
 * # Timezones:
 *   The input value should be the UNIX epoch time in UTC. The output value will be the UNIX epoch
 *   time in UTC. It will be displayed to the user in their local timezone by changing the
 *   underlying timestamp, but keeping the date and time the same.
 *   For example, if the user is in UTC+2, and the input value is 2022-01-01 00:00:00 UTC, it will
 *   be displayed as 2022-01-01 00:00:00 UTC+2. Note that the time appears the same, but the
 *   timezone was changed, meaning that the underlying timestamp was changed. However, the value
 *   returned to the caller view onChange will be the same as the input value, 2022-01-01 00:00:00
 *   UTC.
 *
 *   In other words: simply ensure that you give in the UNIX epoch time in UTC, and you will get
 *   the UNIX epoch time in UTC back.
 */
export function WDateInput(props: WDateInputProps) {
    const { hidden, value, onChange, clearable, disabled, ...otherProps } = wProps(props) //, "now")
    if (hidden) return undefined

    return (
        <DateInput
            {...otherProps}
            disabled={disabled}
            clearable={disabled ? false : clearable}
            value={value ? new DateTime(value as "now" | number).toLocalDate() : null}
            onChange={
                onChange && !disabled ?
                    (date) => onChange(date ? new DateTime(date).toSeconds() : undefined)
                :   undefined
            }
        />
    )
}
