import React from "react"
import { useRouteError } from "react-router-dom"
import { useAppTranslation } from "../../hooks/hooks"
import { Title, Text, Button, Container, Group } from "@mantine/core"
import * as classes from "./ErrorElement.module.css"

export default function ErrorElement(p: { error?: unknown }) {
    const routeError = useRouteError()
    const t = useAppTranslation()
    const error = p.error ?? routeError
    const shouldRefresh =
        error && typeof error === "object" && "name" in error && error.name === "ChunkLoadError"

    if (shouldRefresh) {
        window.location.reload()
        return (
            <Container className={classes.root}>
                <Title className={classes.title}>{t("errors:update_available_reload_page")}</Title>
                <Group justify="center">
                    <Button variant="outline" size="md" onClick={() => window.location.reload()}>
                        {t("errors:reload_page")}
                    </Button>
                </Group>
            </Container>
        )
    }

    return (
        <Container className={classes.root}>
            <Title className={classes.title}>{t("errors:unknown_error_reload_page")}</Title>
            <Text c="dimmed" size="lg" ta="center" className={classes.description}>
                {t("errors:unknown_error_contact_us")}
            </Text>
            <Group justify="center">
                <Button variant="outline" size="md" onClick={() => window.location.reload()}>
                    {t("errors:reload_page")}
                </Button>
            </Group>
        </Container>
    )
}
