const report = {
    appnflat_for_k: "AppNFlat.com - {{buildingName}}",
    appnflat_report: "Rapport AppNFlat.com",
    create_a_statement_for_2: "Création de l'état de compte pour :",
    generating_statement: "Création du rapport...",
    page_on_k: "Page {{number}} sur {{total}}",
    there_was_a_problem_while_generating_the_statement:
        "Un problème est survenu lors de la création du rapport.",
} as const

export default report
