const about = {
    communicate_with_your_co_owners_via_email_or_mobile_app:
        "Communicate with your co-owners via email or mobile app.",
    condo_management_made: "Condo management made",
    contact_us: "Contact Us",
    control_all_your_transactions_with_building_stakeholders:
        "Control all your transactions with building stakeholders",
    easy_accounting: "Easy Accounting",
    easy_inexpensive_and_comprehensive: "Easy, Inexpensive & Comprehensive",
    generate_monthly_cash_flow_reports_as_well_as_financial_statements:
        "Generate monthly cash flow reports as well as financial statements.",
    let_your_co_owners_post_adds_to_each_others_via_mobile_application:
        "Let your co-owners post adds to each others via mobile application.",
    maintain_your_building_data:
        "Maintain your building data (owners, tenants, units, parkings, lockers, banks)",
    manage_your_day_to_day_activities: "Manage your day to day activities",
    organise_and_archive_your_invoices_reports_financial_statements_contracts_bank_statements:
        "Organise and archive your invoices, reports, financial statements, contracts, bank statements, general assemblies, board minutes, etc.",
    select_recipients_per_floor_parking_owners_locker_owners:
        "Select recipients per floor, parking owners, locker owners, etc.",
    want_to_know_more_contact_us_at: "Want to know more? Contact us at:",
    write_to_us_at_for_more_information:
        "Write to us at <a href='mailto:info@appnflat.com'>info@appnflat.com</a> for more information.",
} as const

export default about
