import { configureStore } from "@reduxjs/toolkit"
import { appSlice } from "./appState"
import { cacheSlice } from "./cache"
import * as Sentry from "@sentry/react"

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: (state: RootState) => {
        return {
            ...state,
            app: {
                ...state.app,
                user: { ...state.app.user, email: state.app.user?.email ? "REDACTED" : "ABSENT" },
            },
            cache: {
                ...state.cache,
                users: `Number of users: ${state.cache.users?.length ?? 0}`,
                invitedUsers: `Number of invitedUsers: ${state.cache.invitedUsers?.length ?? 0}`,
            },
        }
    },
})

export const store = configureStore({
    reducer: {
        app: appSlice.reducer,
        cache: cacheSlice.reducer,
    },
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
})

// export type Store = typeof store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
