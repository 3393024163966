import { z } from "zod"
import { Role } from "../@constants/Role"
import { emailString, mediumString } from "./BaseStrings"

export const invitedUserSchema = z.object({
    name: mediumString.optional(),
    email: emailString.email(),
    role: z.nativeEnum(Role),
})
export type InvitedUser = z.infer<typeof invitedUserSchema>
