import { z } from "zod"
import { dineroStorableSchema } from "./Common"
import { aidString, uuidString } from "./BaseStrings"

export const otonomBatchSchema = z.object({
    date: z.number(),
    uuid: uuidString,
    kind: z.enum(["units", "suppliers"]),
    totalAmount: dineroStorableSchema,
    /** The uid of the user who created this batch. */
    uidOfCreator: uuidString.optional(),
    rows: z.array(
        z.object({
            /** The aid of the subtransaction. */
            aid: aidString,
            /** The amount of the subtransaction. */
            amount: dineroStorableSchema,
            /** The date of the transaction. */
            date: z.number(),
            /** The uuid of the transaction. */
            uuid: uuidString,
        })
    ),
})
export type OtonomBatch = z.infer<typeof otonomBatchSchema>
