import { z } from "zod"
import { accountSchema, dineroStorableSchema } from "./Common"
import { mediumString, shortMarkdownString, uuidString, veryShortString } from "./BaseStrings"

/** The schema for a bank account type. */
export const bankAccountTypeSchema = z.enum(["saving", "checking", "investment"])
/** The type of bank account. */
export type BankAccountType = z.infer<typeof bankAccountTypeSchema>

export const coreBankSchema = accountSchema.extend({
    /** The number of the bank account. For display purposes only. */
    number: veryShortString.optional(),
    name: mediumString.optional(),
    /** The uuid of the supplier of the bank (to which late fees, etc. are paid). */
    supplierAccount: uuidString.optional(),
    notes: shortMarkdownString.optional(),
})

export const reconciliationSchema = z.object({
    startingBalance: dineroStorableSchema.optional(),
    /** The list of days delimiting the periods of the bank reconciliation.
     * The first day is the start of the first period, the second day is the end of the first
     * period, the third day is the start of the second period, etc. The last day is the
     * end of the last period.
     *
     * WARNING: The list MUST be sorted in ascending order!
     */
    periods: z.array(z.number()).optional(),
    /** The amounts entered for the reconciliation for each period.
     *
     * This is the actual balance at a given month as entered by the user, multiplied by -1.
     * E.g., if the bank's statement indicates there is 500$ left in the account at the end
     * of the month, the user will enter 500 and we will store -500. This is as bank accounts
     * in the app are operating accounts, which are the opposite of the bank account.
     *
     * WARNING: The list MUST be sorted in the same order as the `periods` list!
     */
    amounts: z.array(dineroStorableSchema).optional(),
    /** The number of periods that have been verified as reconciled.
     *
     * @example
     * If the bank account has 12 periods, and the user has verified the first 6 periods,
     * this value will be 6, so the date of the last verified period will be `periods[5]`.
     */
    periodsVerified: z.number().int().gte(0).optional(),
})
export type BankReconciliation = z.infer<typeof reconciliationSchema>

export type BankWithOrWithoutReconciliation = Bank & {
    reconciliation?: BankReconciliation
    bankAccountType?: BankAccountType
}

export const bankSchema = z.union([
    coreBankSchema.extend({
        /** What kind of bank account this is. */
        bankAccountType: z
            .enum([bankAccountTypeSchema.Values.investment, bankAccountTypeSchema.Values.saving])
            .optional(),
    }),
    coreBankSchema.extend({
        /** What kind of bank account this is. */
        bankAccountType: z.literal(bankAccountTypeSchema.Values.checking),
        /** Details related to the reconciliation of the bank account. */
        reconciliation: reconciliationSchema.optional(),
    }),
])
export type Bank = z.infer<typeof bankSchema>
