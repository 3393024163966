import { useLayoutEffect } from "react"
import { useLocation } from "react-router-dom"

/** Hook to check the app version on route change. */
export default function useVersionCheck() {
    // Check if a version update (refresh) needed on route change
    const location = useLocation()
    useLayoutEffect(() => {
        // If there is an update available and no state passed to route
        if (!location.state && window.localStorage.getItem("version-update-needed")) {
            window.localStorage.removeItem("version-update-needed") // Remove the storage object
            window.location.reload() // Refresh the browser
        }
    }, [location])
}
