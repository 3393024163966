import { z } from "zod"
import { objectInFiscalYearSchema, objectWithEmailsAndPhonesSchema } from "./Common"
import { objectWithAddressSchema } from "./Address"
import { emailString, phoneString, mediumString, shortMarkdownString } from "./BaseStrings"
import { localeSchema } from "./Language"

export const genderSchema = z.enum(["M", "F", "other"])

export const personSchema = objectInFiscalYearSchema
    .merge(objectWithEmailsAndPhonesSchema)
    .merge(objectWithAddressSchema)
    .extend({
        notes: shortMarkdownString.optional(),
        firstName: mediumString.optional(),
        lastName: mediumString.optional(),
        gender: genderSchema.optional(),
        emergencyContact: z
            .object({
                name: mediumString.optional(),
                phone: phoneString.optional(),
                email: emailString.email().optional(),
            })
            .optional(),
        /** The preferred locale of the person. */
        locale: localeSchema.optional(),
    })
export type Person = z.infer<typeof personSchema>
