import z from "zod"
import { zodRegex } from "./zodExtensions"

const isDev = process.env.NODE_ENV !== "production"

export const defaultRemoteConfig: RemoteConfig = {
    REVERSE_PAYMENT: isDev,
    TRANSACTIONS_PER_PAGE: 20,
    LATEST_VERSION: undefined,
    MAINTENANCE_MODE: false,
}

const remoteConfigSchema = z.object({
    /** Whether the NewReverseUnitPaymentButton is available. */
    REVERSE_PAYMENT: z.coerce
        .boolean()
        .optional()
        .transform((v) => isDev || v),

    /** The number of transactions to show per page in the transaction table. */
    TRANSACTIONS_PER_PAGE: z
        .number()
        .optional()
        .transform((v) => (v ? Math.abs(Math.floor(v)) : 20)),

    /** The latest version of the web app. */
    LATEST_VERSION: zodRegex(/^(\d+\.)?(\d+\.)?(\*|\d+)$/).optional(),

    /** Whether the application is in maintenance mode and should be deactivated. */
    MAINTENANCE_MODE: z
        .union([
            z.literal(false),
            z.object({
                /** The message to display to users when the application is in maintenance mode. */
                message: z
                    .object({
                        en: z.string(),
                        fr: z.string(),
                    })
                    .optional(),
                active: z.boolean(),
            }),
        ])
        .optional()
        .transform((v) => (typeof v === "object" ? v : false)),
})

export type RemoteConfig = z.infer<typeof remoteConfigSchema>

export function safeParseRemoteConfig(rc: Record<string, unknown> | undefined): RemoteConfig {
    try {
        return remoteConfigSchema.parse(rc)
    } catch (error) {
        console.error("Error while parsing remote config", error)
        return defaultRemoteConfig
    }
}
