import { z } from "zod"
import { emailString, mediumString, uidString } from "./BaseStrings"

export const globalUserSchema = z.object({
    name: mediumString.optional(),
    uid: uidString,
    email: emailString.optional(),
    /** Whether the user has uploaded a signature. */
    hasSignature: z.boolean().optional(),
})
export type GlobalUser = z.infer<typeof globalUserSchema>
