import { z } from "zod"
import { mediumString, shortString, veryShortString } from "./BaseStrings"

export const addressSchema = z.object({
    city: mediumString.optional(),
    country: shortString.optional(),
    street: mediumString.optional(),
    zip: veryShortString.optional(),
    state: shortString.optional(),
})
export type Address = z.infer<typeof addressSchema>

export const objectWithAddressSchema = z.object({
    address: addressSchema.optional(),
})
export type ObjectWithAddress = z.infer<typeof objectWithAddressSchema>
