import React, { ReactNode } from "react"
import { TKey } from "../../i18n"
import { Tooltip, TooltipProps } from "@mantine/core"
import { useAppLanguage, useAppTranslation } from "../../hooks/hooks"
import { LocalizedString } from "@appnflat-types/types"

type TipProps = Omit<TooltipProps, "label"> & {
    tip: TKey | LocalizedString
    active: boolean
    /** Should NOT be a string or multiple elements. */
    children: ReactNode
}

export default function Tip({ tip, active, children, ...otherProps }: TipProps) {
    const t = useAppTranslation()
    const language = useAppLanguage()
    if (!active) return children

    return (
        <Tooltip
            label={typeof tip === "string" ? t(tip) : tip?.[language]}
            withArrow
            multiline
            {...otherProps}
            transitionProps={
                process.env.NODE_ENV === "test" ?
                    { ...(otherProps?.transitionProps ?? {}), duration: 0 }
                :   otherProps?.transitionProps
            }
        >
            {children}
        </Tooltip>
    )
}
