import React from "react"
import {
    IconBrandStripe,
    IconBuildingBank,
    IconCalendar,
    IconCloudLock,
    IconFileDownload,
    IconFriends,
    IconPlus,
    IconPrinter,
    IconTrash,
    IconUser,
} from "@tabler/icons-react"
import { useMemo } from "react"
import * as classes from "./LoadingIcon.module.css"

export type LoadingIcon =
    | "cloud-lock"
    | "report"
    | "fiscal-year"
    | "stripe"
    | "printer"
    | "user"
    | "trash"
    | "plus"
    | "bank"
    | "people"

export function LoadingIcon(loading: { icon?: LoadingIcon } | undefined) {
    const Icon = useMemo(() => {
        switch (loading?.icon) {
            case "cloud-lock":
                return IconCloudLock
            case "report":
                return IconFileDownload
            case "fiscal-year":
                return IconCalendar
            case "stripe":
                return IconBrandStripe
            case "printer":
                return IconPrinter
            case "user":
                return IconUser
            case "trash":
                return IconTrash
            case "plus":
                return IconPlus
            case "bank":
                return IconBuildingBank
            case "people":
                return IconFriends
            case undefined:
                return undefined
            default: {
                const exhaustiveCheck: undefined = loading?.icon
                console.error(`Unhandled loading icon: ${exhaustiveCheck}`)
                return undefined
            }
        }
    }, [loading])

    if (!Icon) return null
    return <Icon className={classes.pulse} />
}
