import { z } from "zod"
import { objectInFiscalYearSchema, objectWithMonthlyFeesSchema } from "./Common"
import { ParsingErrors } from "./parsingErrors"
import { shortString, uuidString, veryShortString } from "./BaseStrings"

export const parkingSchema = objectInFiscalYearSchema.merge(objectWithMonthlyFeesSchema).extend({
    number: veryShortString.optional(),
    /** Details about who is renting the parking. */
    renter: z
        .object({
            /** The uuid of the person renting the parking. */
            person: uuidString.optional(),
            /** The uuid of the unit renting the parking. */
            unit: uuidString.optional(),
        })
        .optional(),
    voteShare: z
        .number()
        .min(0, { message: ParsingErrors.must_be_positive })
        .max(1, { message: ParsingErrors.must_be_less_than_100 })
        .optional(),
    cadastreNumber: shortString.optional(),
    licensePlate: veryShortString.optional(),
})
export type Parking = z.infer<typeof parkingSchema>
