import React, { createContext, useState, useEffect, ReactNode } from "react"
import { onSnapshot, doc } from "firebase/firestore"
import { db } from "firebaseSetup"
import { defaultRemoteConfig, safeParseRemoteConfig } from "@appnflat-types/RemoteConfig"
import { MaintenanceMode } from "components/Other/MaintenanceMode"

export const RemoteConfigContext = createContext(defaultRemoteConfig)

export function RemoteConfigProvider({ children }: { children: ReactNode }) {
    const [flags, setFlags] = useState(defaultRemoteConfig)

    useEffect(() => {
        try {
            const unsubscribe = onSnapshot(
                doc(db, "settings", "remoteConfig"),
                (snap) => {
                    const remoteFlags = safeParseRemoteConfig(snap.data())
                    console.log("Remote Config flags updated to", remoteFlags)
                    setFlags(remoteFlags)
                },
                (error) => console.error("Error while updating remote config flags", error)
            )
            return unsubscribe
        } catch (error) {
            console.error("Error while updating remote config flags", error)
        }
    }, [])

    /* Check if the app has an update available. */
    useEffect(() => {
        try {
            const appVersion = process.env.REACT_APP_VERSION
            if (!appVersion || appVersion.endsWith("-rc")) return
            if (
                flags.LATEST_VERSION &&
                appVersion.localeCompare(flags.LATEST_VERSION, undefined, {
                    numeric: true,
                    sensitivity: "base",
                }) < 0
            ) {
                window.localStorage.setItem("version-update-needed", "true")
            } else {
                window.localStorage.removeItem("version-update-needed")
            }
        } catch (error) {
            console.log("Error checking if update available", error)
        }
    }, [flags.LATEST_VERSION])

    if (flags.MAINTENANCE_MODE && flags.MAINTENANCE_MODE.active) {
        return <MaintenanceMode maintenanceMessage={flags.MAINTENANCE_MODE.message} />
    } else {
        return <RemoteConfigContext.Provider value={flags}>{children}</RemoteConfigContext.Provider>
    }
}
