import { z } from "zod"
import { dineroStorableSchema } from "./Common"
import { aidString, shortMarkdownString, uuidString, veryShortString } from "./BaseStrings"

export const checkSchema = z.object({
    date: z.number(),
    amount: dineroStorableSchema,
    unitAID: aidString,
    uuid: uuidString,
    number: veryShortString,
    notes: shortMarkdownString.optional(),
})
export type Check = z.infer<typeof checkSchema>
