import { z } from "zod"
import { aidString, shortString } from "./BaseStrings"
import { objectWithUUIDSchema, positiveDineroStorableSchema } from "./Common"

export const creditNoteSchema = objectWithUUIDSchema.extend({
    aid: aidString,
    /** The number of the credit note. */
    number: shortString,
    amount: z.object({
        /** The amount of the credit note. __SHOULD NEVER BE CHANGED AFTER CREATION__. */
        original: positiveDineroStorableSchema,
        /** The amount of the credit note already spent. */
        spent: positiveDineroStorableSchema,
        /** The amount of the credit note that is assigned to a pending transaction. */
        pending: positiveDineroStorableSchema,
    }),
    /** Whether the credit note is entirely used. */
    allUsed: z.boolean(),
})
export type CreditNote = z.infer<typeof creditNoteSchema>
