import { z } from "zod"
import { accountSchema } from "./Common"
import { mediumString } from "./BaseStrings"

export const CATEGORY_PARENTS = [
    "assets",
    "liabilities",
    "capital",
    "revenues",
    "expenses",
    "accounts-payable",
    "accounts-receivable",
] as const
/** All the possible parents for a category. */
export type CategoryParent = (typeof CATEGORY_PARENTS)[number]

export const categorySchema = accountSchema.extend({
    name: mediumString.optional(),
    /** The kind of the parent. */
    parent: z.enum(CATEGORY_PARENTS).optional(),
})
export type Category = z.infer<typeof categorySchema>
