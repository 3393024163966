import { DeepPartialWithRequired } from "../@appnflat-types/helpers"
import { Bank } from "../@appnflat-types/Bank"
import { Budget } from "../@appnflat-types/Budget"
import { Building } from "../@appnflat-types/Building"
import { Category } from "../@appnflat-types/Category"
import { Check } from "../@appnflat-types/Check"
import { EmailTemplate } from "../@appnflat-types/EmailTemplate"
import { InvitedUser } from "../@appnflat-types/InvitedUser"
import { Locker } from "../@appnflat-types/Locker"
import { OtonomBatch } from "../@appnflat-types/OtonomBatch"
import { Parking } from "../@appnflat-types/Parking"
import { CustomPenalty, DefaultPenalty } from "../@appnflat-types/Penalty"
import { Person } from "../@appnflat-types/Person"
import { Post } from "../@appnflat-types/Post"
import { OtonomFileId } from "../@appnflat-types/PreparedOtonomFile"
import { Supplier } from "../@appnflat-types/Supplier"
import { Transaction } from "../@appnflat-types/Transaction"
import { Unit } from "../@appnflat-types/Unit"
import { BuildingUser } from "../@appnflat-types/BuildingUser"
import { MetadataDocuments } from "../@appnflat-types/FirestoreCollections"
import { Request, RequestTag } from "../@appnflat-types/Request"
import { RequestEmailDocument } from "../@appnflat-types/RequestEmail"
import { NotificationSent } from "../@appnflat-types/Notifications"
import { Log } from "../@appnflat-types/Log"
import { DBLock } from "../@appnflat-types/DBLock"
import { CreditNote } from "../@appnflat-types/CreditNote"

export type IdForCollectionInput =
    | {
          collection: "units"
          value: DeepPartialWithRequired<Unit, "uuid" | "fiscalYear">
      }
    | {
          collection: "suppliers"
          value: DeepPartialWithRequired<Supplier, "uuid" | "fiscalYear">
      }
    | {
          collection: "banks"
          value: DeepPartialWithRequired<Bank, "uuid" | "fiscalYear">
      }
    | {
          collection: "people"
          value: DeepPartialWithRequired<Person, "uuid" | "fiscalYear">
      }
    | {
          collection: "categories"
          value: DeepPartialWithRequired<Category, "uuid" | "fiscalYear">
      }
    | {
          collection: "parkings"
          value: DeepPartialWithRequired<Parking, "uuid" | "fiscalYear">
      }
    | {
          collection: "lockers"
          value: DeepPartialWithRequired<Locker, "uuid" | "fiscalYear">
      }
    | {
          collection: "transactions"
          value: DeepPartialWithRequired<Transaction, "uuid" | "fiscalYear">
      }
    | {
          collection: "unreconciledTransactions"
          value: DeepPartialWithRequired<Transaction, "uuid" | "fiscalYear">
      }
    | {
          collection: "otonomBatches"
          value: DeepPartialWithRequired<OtonomBatch, "uuid">
      }
    | {
          collection: "posts"
          value: DeepPartialWithRequired<Post, "uuid">
      }
    | {
          collection: "penalties"
          value:
              | DeepPartialWithRequired<CustomPenalty, "uuid" | "kind">
              | DeepPartialWithRequired<DefaultPenalty, "kind">
      }
    | {
          collection: "checks"
          value: DeepPartialWithRequired<Check, "uuid">
      }
    | {
          collection: "requests"
          value: DeepPartialWithRequired<Request, "uuid">
      }
    | {
          collection: "requestEmails"
          value: DeepPartialWithRequired<RequestEmailDocument, "uuid">
      }
    | {
          collection: "requestTags"
          value: DeepPartialWithRequired<RequestTag, "uuid">
      }
    | {
          collection: "budgets"
          value: DeepPartialWithRequired<Budget, "uuid">
      }
    | {
          collection: "preparedOtonomFiles"
          value: OtonomFileId
      }
    | {
          collection: "buildings"
          value: DeepPartialWithRequired<Building, "buildingRef">
      }
    | {
          collection: "users"
          value: DeepPartialWithRequired<BuildingUser, "uid">
      }
    | {
          collection: "invitedUsers"
          value: DeepPartialWithRequired<InvitedUser, "email">
      }
    | {
          collection: "emailTemplates"
          value: DeepPartialWithRequired<EmailTemplate, "kind">
      }
    | {
          collection: "secretsBankAccounts"
          value: { aid: string }
      }
    | {
          collection: "metadata"
          value: { id: MetadataDocuments }
      }
    | {
          collection: "notificationsSent"
          value: DeepPartialWithRequired<NotificationSent, "notificationId">
      }
    | {
          collection: "logs"
          value: DeepPartialWithRequired<Log, "uuid">
      }
    | {
          collection: "locks"
          value: DeepPartialWithRequired<DBLock, "name">
      }
    | {
          collection: "creditNotes"
          value: DeepPartialWithRequired<CreditNote, "uuid">
      }

/** Returns the id for a collection. */
// export function idForCollection(params: DeepRequired<IdForCollectionInput>): string
// export function idForCollection(params: IdForCollectionInput): string | undefined
export function idForCollection({ collection, value }: IdForCollectionInput): string {
    // if (!value) return undefined
    switch (collection) {
        case "metadata":
            return value.id
        case "users":
            return value.uid
        case "invitedUsers":
            return value.email
        case "emailTemplates":
            return value.kind
        case "buildings":
            return value.buildingRef
        case "otonomBatches":
        case "posts":
        case "checks":
        case "requests":
        case "requestEmails":
        case "requestTags":
        case "budgets":
        case "logs":
        case "creditNotes":
            return value.uuid
        case "locks":
            return value.name
        case "penalties":
            // if (!value.kind) return undefined
            return value.kind === "customPenalty" || value.kind === "customFee" ?
                    value.uuid
                :   value.kind
        case "preparedOtonomFiles":
            return value.kind === "units" ?
                    "all-units"
                :   `supplier-${value.supplierUUID}#${value.fiscalYear}`
        case "secretsBankAccounts":
            return value.aid
        // case "verificationCodes":
        //     return value.email
        case "notificationsSent": {
            const id = value.notificationId
            // if (!id) return undefined
            switch (id.type) {
                case "requestEmail":
                    return `${id.requestEmailUUID}#${id.type}`
                case "unpaidBalance":
                case "insuranceExpiration":
                case "insuranceInfoMissing":
                case "waterHeaterExpiration":
                case "waterHeaterInfoMissing": {
                    const date = id.date.slice(
                        0,
                        id.frequency === "yearly" ? 4
                        : id.frequency === "monthly" ? 7
                        : 10
                    )
                    return `${id.unitUUID}#${id.type}#${date}`
                }
                case "notifyRecipientsOfPost":
                    return `${id.postUUID}#${id.type}`
                case "pendingTransactionApproval":
                    return `${id.transactionUUID}#${id.type}`
                case "newCheckSavedForUnit":
                    return `${id.unitUUID}#${id.checkUUID}#${id.type}`
                case "emailVerificationCode":
                    return `${id.uuid}#${id.type}`
                case "userAddedToBuilding":
                    return `${id.userUID}#${id.type}`
                case "userInvitedToBuilding":
                    return `${id.uuid}#${id.type}`
                case "unitStatement":
                    return `${id.unitUUID}#${id.day}#${id.type}`
                case "unitReversedPayment":
                    return `${id.unitUUID}#${id.transactionUUID}#${id.type}`
                default: {
                    const exhaustiveCheck: never = id
                    throw new Error(`Unhandled notification type: ${exhaustiveCheck}`)
                }
            }
        }
        default:
            // if (!value.uuid || !value.fiscalYear) return undefined
            return `${value.uuid}#${value.fiscalYear}`
    }
}
