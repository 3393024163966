import { createSlice } from "@reduxjs/toolkit"
import { RecursiveKeyOf, GetFieldType } from "@appnflat-types/helpers"
import { TypeByCollection } from "@appnflat-types/Collection"
import { WebCacheCollections, initialState } from "./cacheHelpers"
import {
    _emptyOfBuildingData,
    _emptyOfFiscalYearData,
    _setBuildingFromServer,
    _setCollectionFromServer,
    _setObjectFromServer,
    _updateField,
} from "./cacheReducers"
export {
    suppliersOptionsSelector,
    cachedBuildingsSelector,
    transactionsSelector,
    cachedObjectSelector,
    unitsOptionsSelector,
    peopleOptionsSelector,
    cachedBuildingSelector,
    cachedCollectionSelector,
    cachedObjectFieldSelector,
    transactionPartiesSelector,
    cachedUserInBuildingSelector,
} from "./cacheSelectors"

export const cacheSlice = createSlice({
    name: "cache",
    initialState,
    reducers: {
        /** Empties the cache. */
        emptyCache: () => initialState,

        /** Empties the cache of a given building. */
        emptyCacheOfBuildingData: _emptyOfBuildingData,

        /** Empties the cache of a given fiscal year. */
        emptyCacheOfFiscalYearData: _emptyOfFiscalYearData,

        /**
         * Updates a field within an object (within the `edits` field). This is untyped and
         * should not be exported. Set typed version at bottom of file.
         */
        updateField: _updateField,

        /** Sets the `original` field for an object. */
        setObjectFromServer: _setObjectFromServer,

        /** Sets the building details for a building. */
        setBuildingFromServer: _setBuildingFromServer,

        /** Sets a collection. Will just update the `original` field in the entries. */
        setCollectionFromServer: _setCollectionFromServer,
    },
})

export const {
    emptyCache,
    setObjectFromServer,
    setBuildingFromServer,
    setCollectionFromServer,
    emptyCacheOfBuildingData,
    emptyCacheOfFiscalYearData,
} = cacheSlice.actions

/**
 * Updates a field within an object (within the `edits` field). This is the typed version of
 * `_updateField`.
 */
export function updateField<
    C extends WebCacheCollections,
    F extends RecursiveKeyOf<TypeByCollection[C]>,
>(action: {
    /** The type of object to update. */
    collection: C
    /** The uuid of the object to update. */
    uuid: string
    /** The fiscal year the object is in. If the object has no fiscal year, this will have no impact. */
    fiscalYear: number
    /** The field to update. */
    field: F
    /** The value to set. */
    value: GetFieldType<TypeByCollection[C], F>
}) {
    return cacheSlice.actions.updateField(action)
}
