import React from "react"
import { TextInput, TextInputProps } from "@mantine/core"
import { WProps, wProps } from "./WHelpers"

type WTextInputProps = WProps<TextInputProps>

/** A wrapper around TextInput. */
export function WTextInput(props: WTextInputProps) {
    const { hidden, ...otherProps } = wProps(props)
    if (hidden) return undefined

    return <TextInput {...otherProps} />
}
