import { useAppSelector } from "./hooks"
import { userRoleSelector } from "store/appState"
import { Role } from "@constants/Role"
import { cachedBuildingSelector } from "store/cache"

const permissionsCheck = {
    "accounts.read": [
        {
            roles: [Role.approver, Role.admin, Role.accountant, Role.accountantWrite],
        },
    ],
    "transactions.read": [
        {
            roles: [Role.approver, Role.admin, Role.accountant, Role.accountantWrite],
        },
        {
            roles: [Role.owner],
            // ownersCanAccessFinances: true,
        },
    ],
    "transactions.write": [
        {
            roles: [Role.approver, Role.admin, Role.accountantWrite],
        },
    ],
    "people.read.all": [
        {
            roles: [Role.approver, Role.admin, Role.accountant, Role.accountantWrite],
        },
    ],
    "people.read.self": [
        {
            roles: [Role.owner, Role.resident],
            // ownersCanAccessFinances: true,
        },
    ],
    "social.read": [
        {
            roles: [Role.approver, Role.admin, Role.owner, Role.resident],
        },
    ],
    "requests.emails": [
        {
            roles: [Role.approver, Role.admin, Role.handler],
        },
    ],
    // Creating a request for a unit owned or rented by the user
    "requests.create.for_self": [
        {
            roles: [Role.owner, Role.resident],
        },
    ],
    "requests.create.for_any": [
        {
            roles: [Role.approver, Role.admin, Role.handler],
        },
    ],
    "requests.tags.edit": [
        {
            roles: [Role.approver, Role.admin, Role.handler],
        },
    ],
    "budgets.edit": [
        {
            roles: [Role.approver, Role.admin, Role.accountantWrite],
        },
    ],
    "budgets.view": [
        {
            roles: [Role.approver, Role.admin, Role.accountant, Role.accountantWrite, Role.owner],
        },
    ],
}

export type PermissionKey = keyof typeof permissionsCheck | undefined

/** A custom hook that returns whether the user has a given permission. */
export function useHasPermission(permissionKey: PermissionKey) {
    const userRole = useAppSelector(userRoleSelector)
    // const isBanned = useAppSelector(userBannedFromSocialSelector)
    const building = useAppSelector(cachedBuildingSelector)

    if (!permissionKey) return true
    if (!userRole) return false

    return permissionsCheck[permissionKey].some(
        (check) =>
            check.roles.includes(userRole) &&
            (!("ownersCanAccessFinances" in check) || building?.ownersCanAccessFinances)
    )
}
