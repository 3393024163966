/** A list of all payment methods. */
export const PAYMENT_METHODS = [
    "cash",
    "check",
    "credit-card",
    "debit-card",
    "wire-transfer",
    "otonom",
    "pre-authorized-debit",
] as const
export type PaymentMethod = (typeof PAYMENT_METHODS)[number]
