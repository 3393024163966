import React from "react"
import ReactDOM from "react-dom/client"
import "./css/App.css"
import "./css/Static.css"
import "./css/Static.mobile.css"
import "./css/Reset.css"
import { theme } from "./mantineTheme"
import App from "./App"
import { Provider } from "react-redux"
import { store } from "./store/store"
import { ColorSchemeScript, MantineProvider } from "@mantine/core"

const root = ReactDOM.createRoot(document.getElementById("root")!)
document.querySelector("html")?.setAttribute("platform", process.env.PLATFORM ?? "web")

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ColorSchemeScript defaultColorScheme="auto" />
            <MantineProvider theme={theme} defaultColorScheme="auto">
                <App />
            </MantineProvider>
        </Provider>
    </React.StrictMode>
)
