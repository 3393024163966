/**
 * Takes in a person object and returns a formatted name.
 *
 * @param person - Person or owner object (must have a firstName and / or lastName field)
 * @param lastNameFirst - Whether to display the last name first. Default is false.
 * @return A well formatted name, ready to display
 */
export default function personName(
    person: { firstName?: string; lastName?: string; uuid?: string } | undefined,
    lastNameFirst = false
) {
    if (!person) return ""
    else if (!person.firstName && !person.lastName) return person.uuid ?? ""
    else if (lastNameFirst && person.lastName && person.firstName)
        return `${person.lastName}, ${person.firstName}`.trim()
    else return `${person.firstName ?? ""} ${person.lastName ?? ""}`.trim()
}
