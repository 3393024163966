import { z } from "zod"

/** A locale (i.e., language + region). */
export enum Locale {
    English_CA = "en-CA",
    French_CA = "fr-CA",
}
/** A locale (i.e., language + region). */
export const localeSchema = z.nativeEnum(Locale)

/** A language (i.e., no region). */
export enum Language {
    English = "en",
    French = "fr",
}
/** A language (i.e., no region). */
export const languageSchema = z.nativeEnum(Language)

/** Converts a locale to a language. */
export function localeToLanguage(locale: Locale): Language {
    return locale === Locale.French_CA ? Language.French : Language.English
}

/** Converts a language to a locale. */
export function languageToLocale(language: Language): Locale {
    return language === Language.French ? Locale.French_CA : Locale.English_CA
}

/** Returns the display name of a language. */
export function languageDisplayName(language: Language | Locale | undefined) {
    let lang: Language | undefined = undefined
    if (language === undefined) {
        return undefined
    } else if (Object.values(Locale).includes(language as Locale)) {
        lang = localeToLanguage(language as Locale)
    } else if (Object.values(Language).includes(language as Language)) {
        lang = language as Language
    }

    switch (lang) {
        case Language.English:
            return "English"
        case Language.French:
            return "Français"
        default:
            return undefined
    }
}
