export const sharedConfig = {
    /** The max length of a fiscal year, in weeks.
     *
     * If a building has a fiscal year that is longer than this value, admin users
     * that log into the building will be forced to create a new fiscal year.
     */
    MAX_WEEKS_IN_FY: 114 as const,
    /** The maximum precision of a vote share.
     *
     * A value of `x` means that the vote share is rounded to `x` decimal places when written
     * as a percentage.
     *
     * @example if the vote share is 0.12345678 and `MAX_VOTE_SHARE_PRECISION` is 2, the
     * vote share will be displayed as 12.35%.
     */
    MAX_VOTE_SHARE_PRECISION: 8 as const,
    /** Whether to display units, parkings, and lockers `noMonthlyFee` option. */
    NO_MONTHLY_FEE_OPTION: false as const,
}
