// Note: the following keys are safe to expose, they only serve to identify our app.
const firebaseConfig = {
    apiKey: "AIzaSyCKqoXtuuHyUQ3EzHUZqFBhW-P2iLp_9Zs",
    authDomain: "appnflat-canada.firebaseapp.com",
    projectId: "appnflat-canada",
    storageBucket: "appnflat-canada.appspot.com",
    messagingSenderId: "606804700574",
    appId: "1:606804700574:web:5542f07364e20cfda67a8f",
    measurementId: "G-JL96WES7LB",
}
import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"
import { browserSessionPersistence, indexedDBLocalPersistence, initializeAuth } from "firebase/auth"
import {
    initializeFirestore,
    persistentLocalCache,
    persistentMultipleTabManager,
} from "firebase/firestore"
import { getPerformance } from "firebase/performance"

export const app = initializeApp(firebaseConfig)
export const storage = getStorage(app)
export const db = initializeFirestore(app, {
    localCache: persistentLocalCache({
        tabManager: persistentMultipleTabManager(),
    }),
})
export const auth = initializeAuth(app, {
    persistence:
        /* istanbul ignore next */
        process.env.PLATFORM !== "web" ? indexedDBLocalPersistence : browserSessionPersistence,
})
/* istanbul ignore next */
export const performance = process.env.IS_TEST ? undefined : getPerformance(app)
