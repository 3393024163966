import { z } from "zod"
import { objectInFiscalYearSchema, objectWithMonthlyFeesSchema } from "./Common"
import { ParsingErrors } from "./parsingErrors"

export const lockerSchema = objectInFiscalYearSchema.merge(objectWithMonthlyFeesSchema).extend({
    number: z.string().optional(),
    voteShare: z
        .number()
        .min(0, { message: ParsingErrors.must_be_positive })
        .max(1, { message: ParsingErrors.must_be_less_than_100 })
        .optional(),
    cadastreNumber: z.string().optional(),
})
export type Locker = z.infer<typeof lockerSchema>
