const about = {
    communicate_with_your_co_owners_via_email_or_mobile_app:
        "Communiquez avec vos co-propriétaires par courriel ou à travers une application mobile",
    condo_management_made: "La gestion de condo",
    contact_us: "Contactez-nous",
    control_all_your_transactions_with_building_stakeholders:
        "Contrôlez toutes les transactions avec les parties prenantes de votre immeuble",
    easy_accounting: "Comptabilité simplifiée",
    easy_inexpensive_and_comprehensive: "simple, bon marché & complète",
    generate_monthly_cash_flow_reports_as_well_as_financial_statements:
        "Produisez des rapports mensuels de trésorerie et des états financiers.",
    let_your_co_owners_post_adds_to_each_others_via_mobile_application:
        "Permettez à vos copropriétaires de publier des annonces à travers une application mobile",
    maintain_your_building_data:
        "Administrez vos données (propriétaires, locataires, unités, parkings, casiers, banques)",
    manage_your_day_to_day_activities: "Gérez vos activités quotidiennes",
    organise_and_archive_your_invoices_reports_financial_statements_contracts_bank_statements:
        "Organisez et archivez vos factures, rapports, états financiers, contrats, relevés bancaires, minutes d'assemblées générales et de conseil d'administration, etc.",
    select_recipients_per_floor_parking_owners_locker_owners:
        "choisissez vos destinataires par étage, propriétaires de parking, propriétaires de casiers, etc.",
    want_to_know_more_contact_us_at: "Vous voulez en savoir davantage? Contactez-nous à :",
    write_to_us_at_for_more_information:
        "Écrivez-nous à <a href='mailto:info@appnflat.com'>info@appnflat.com</a> pour plus d'information.",
} as const

export default about
