/*
 * Useful functions to manipulate Dinero objects.
 */

import DineroFactory, { Currency, Dinero } from "dinero.js"
import { DineroStorable } from "../@appnflat-types/Common"
import { RecursiveKeysOfType } from "../@appnflat-types/helpers"
import { get } from "./objects"

/** Returns the absolute value of a Dinero object. */
export function absoluteValue(a: Dinero) {
    if (a.isNegative()) return a.multiply(-1)
    else return a
}

/** Returns the sign (-1, 0, or 1) of a Dinero object. */
export function signOfDinero(a: Dinero): -1 | 0 | 1 {
    if (a.isZero()) return 0
    else if (a.isPositive()) return 1
    else return -1
}

/** Checks if a Dinero object is strictly positive. */
export function isStrictlyPositive(a: Dinero) {
    return a.isPositive() && !a.isZero()
}

/** Checks if a Dinero object is strictly negative. */
export function isStrictlyNegative(a: Dinero) {
    return a.isNegative() && !a.isZero()
}

/** Takes in a {@link DineroStorable} object and returns a Dinero object. */
export function safeDineroFactory(
    obj: Partial<DineroStorable> | undefined | null,
    currency: Currency | undefined | null
): Dinero {
    const safeObj = {
        amount: obj?.amount ?? 0,
        currency: obj?.currency ?? currency ?? "CAD",
        precision: obj?.precision ?? 2,
        locale: obj?.locale,
    }
    return DineroFactory(safeObj)
}

/** Takes in a number and returns a Dinero object. */
export function toDinero(
    amount: number | undefined | null,
    currency: Currency | undefined | null
): Dinero {
    const multiplier = 10 ** (DineroFactory.defaultPrecision ?? 2)
    const fixedAmount = (!amount && amount !== 0) || Number.isNaN(amount) ? 0 : amount
    return safeDineroFactory({ amount: Math.round(multiplier * fixedAmount) }, currency)
}

/** Converts a number to a {@link DineroStorable} object. */
export function toDineroStorable(
    amount: number | undefined | null,
    currency: Currency | undefined | null
): DineroStorable {
    return toDinero(amount, currency).toObject()
}

/** Returns the sum of all the Dinero values. */
export function sumDineroArray(
    array: (Dinero | undefined | null)[] | undefined,
    currency: Currency | undefined | null
) {
    if (!array) return safeDineroFactory({}, currency)
    return array.reduce(
        (acc: Dinero, amount) => (amount ? acc.add(amount) : acc),
        safeDineroFactory({}, currency)
    )
}

/** Returns the sum of all the DineroStorable values. */
export function sumDineroStorableArray(
    array: (Partial<DineroStorable> | undefined | null)[] | undefined,
    currency: Currency | undefined | null
) {
    return sumDineroArray(
        array?.map((v) => safeDineroFactory(v, currency)),
        currency
    )
}

/** Returns the sum of all the DineroStorable values. */
export function sumDineroStorableArrayInField<T extends object>(
    array: T[] | undefined,
    currency: Currency | undefined | null,
    field: RecursiveKeysOfType<T, Partial<DineroStorable> | undefined | null>
) {
    return sumDineroArray(
        array?.map((v) =>
            safeDineroFactory(get<any, any, Partial<DineroStorable>>(v, field), currency)
        ),
        currency
    )
}

/**
 * Verifies if two Dinero amounts are within `epsilon` currency units of each other.
 *
 * @example
 * almostEqual(toDinero(1.1, "CAD"), toDinero(1.15, "CAD"), 0.1) // true
 * almostEqual(toDinero(1.1, "CAD"), toDinero(1.15, "CAD"), 0.01) // false
 */
export function almostEqual(a: Dinero, b: Dinero, epsilon: number) {
    return Math.abs(a.subtract(b).toUnit()) < epsilon
}

/**
 * Returns the smallest of the two Dinero amounts.
 *
 * @example
 * minDinero(toDinero(1.1, "CAD"), toDinero(1.15, "CAD")) // toDinero(1.1, "CAD")
 * minDinero(toDinero(1.1, "CAD"), toDinero(1.05, "CAD")) // toDinero(1.05, "CAD")
 */
export function minDinero(a: Dinero, b: Dinero) {
    return a.lessThan(b) ? a : b
}
