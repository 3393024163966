import React, { Suspense, lazy } from "react"
import type { ImgWithFallbackProps } from "./ImgWithFallbackInternal"
const ImgWithFallbackInternal = lazy(() => import("./ImgWithFallbackInternal"))

export default function ImgWithFallback(props: ImgWithFallbackProps) {
    return (
        <Suspense fallback={<div />}>
            <ImgWithFallbackInternal {...props} />
        </Suspense>
    )
}
