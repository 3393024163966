import { z } from "zod"
import { objectWithUUIDSchema } from "./Common"
import { mediumString, shortMarkdownString } from "./BaseStrings"

export const postRecipientSchema = z.union([
    z.literal("all"),
    z.literal("parkings"),
    z.literal("lockers"),
    z.literal("owners"),
    z.literal("residents"),
    z.custom<`floor-${string}`>((val) => {
        return typeof val === "string" ? val.startsWith("floor-") : false
    }),
])
export type PostRecipient = z.infer<typeof postRecipientSchema>

export const postSchema = objectWithUUIDSchema.extend({
    date: z.number(),
    author: z.string().max(40),
    authorName: mediumString.optional(),
    title: mediumString,
    body: shortMarkdownString,
    recipients: z.array(postRecipientSchema),
    /** Whether to send an email notifications to the post recipients. Can only be activated by admins. */
    sendEmail: z.boolean().optional(),
    /** Attached files. Files are stored in `bucket/buildings/{buildingRef}/posts/{postUUID}/`. */
    attachments: z
        .array(
            z.object({
                /** The name to display to the user as the file name. */
                fileDisplayName: z.string().max(300),
                /**
                 * The id of the file, in the format `{uuid}.{extension}` (`extension` being one of
                 * `pdf`, `webp`, etc.) that is the name of a file.
                 */
                fileID: z.string(),
            })
        )
        .optional(),
})
export type Post = z.infer<typeof postSchema>
