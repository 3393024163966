import { z } from "zod"
import { ParsingErrors } from "./parsingErrors"
import { longMarkdownString, mediumString, shortString, uuidString } from "./BaseStrings"

/** The status of the request. */
export const requestStatusSchema = z.enum(["open", "closed", "underway"])
/** The status of the request. */
export type RequestStatus = z.infer<typeof requestStatusSchema>

/** The priority of the request. */
export const requestPrioritySchema = z.enum(["high", "medium", "low"])
/** The priority of the request. */
export type RequestPriority = z.infer<typeof requestPrioritySchema>

/**
 * A request.
 * Corresponds to the document `db/buildings/{buildingRef}/requests/{uuid}`.
 */
export const requestSchema = z.object({
    uuid: uuidString,
    /** The uuids of the people who initiated the request. */
    requestors: z.array(uuidString).optional(),
    /** Units associated to this request. */
    unitUUIDs: z.array(uuidString).optional(),
    /** The title of the request. */
    title: mediumString,
    /** The date at which the request was created. */
    date: z.number(),
    /** The date at which the request was closed. */
    closeDate: z.number().optional(),
    /** The tags of the request. Should be a list of uuids of `RequestTag`. */
    tags: z.array(uuidString).optional(),
    /** The status of the request. This is managed by the user. */
    status: requestStatusSchema,
    /** The `uid` of the person responsible for the request. */
    handler: uuidString.optional(),
    /** How urgent this request is. */
    priority: requestPrioritySchema,
    /** Notes about the request. */
    notes: longMarkdownString.optional(),
})
/**
 * A request.
 * Corresponds to the document `db/buildings/{buildingRef}/requests/{uuid}`.
 */
export type Request = z.infer<typeof requestSchema>

/**
 * A request tag.
 * Corresponds to the document `db/buildings/{buildingRef}/requestTags/{uuid}`.
 */
export const requestTagSchema = z.object({
    uuid: uuidString,
    /** The name of the tag. */
    name: shortString,
    /** The color of the tag, used when displaying it as a badge. */
    color: z
        .string()
        .regex(/^#([0-9a-fA-F]{6})$/, { message: ParsingErrors.invalid_hex_color_code })
        .optional(),
})
/**
 * A request tag.
 * Corresponds to the document `db/buildings/{buildingRef}/requestTags/{uuid}`.
 */
export type RequestTag = z.infer<typeof requestTagSchema>
