import z from "zod"

/** A schema of the data describing a file. */
export const fileUploadSchema = z.object({
    /** The file extension. Should **not** start with a `.`. */
    extension: z.string(),
    /** The document data, as a base64 encoded Data URL.
     * @example "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABaElEQVR42mNk"
     */
    dataURL: z.string(),
    /** The MIME type of the file. */
    mimeType: z.string(),
    /** The name of the file, including the extension. */
    name: z.string(),
})
/** The data describing a file. */
export type FileUpload = z.infer<typeof fileUploadSchema>
