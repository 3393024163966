/**
 * The possible roles for a user in a building.
 */
export enum Role {
    /** An admin with approval permission */
    approver = "approver",
    /** An admin without approval permission */
    admin = "admin",
    /** A requests handler. */
    handler = "handler",
    /** An accountant with write permissions */
    accountantWrite = "accountantWrite",
    /** An accountant without write permissions */
    accountant = "accountant",
    /** A unit owner */
    owner = "owner",
    /** A unit resident */
    resident = "resident",
}
