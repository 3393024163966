import { z } from "zod"

/**
 * The document `unitCounts`.
 * It holds data concerning the total unarchived unit count of a building for the past
 * three months, as an object with keys being ISO date and values being the unit count.
 */
export const unitCountsSchema = z.object({
    count: z.record(z.string(), z.number().optional()),
    id: z.literal("unitCounts"),
})
export type UnitCounts = z.infer<typeof unitCountsSchema>
