import { z } from "zod"
import { mediumString, shortMarkdownString } from "./BaseStrings"

export const emailTemplateKindSchema = z.enum([
    /** Reminder of an insurance being invalid. */
    "insurance-incomplete-reminder",
    /** Reminder when an insurance is about to expire. */
    "insurance-about-to-expire-reminder",
    /** Reminder of a water heater being invalid. */
    "water-heater-incomplete-reminder",
    /** Reminder when a water heater is about to expire. */
    "water-heater-about-to-expire-reminder",
    /** Reminder for the n-th week of a unit having an unpaid balance. */
    "unit-unpaid-balance-week-0",
    "unit-unpaid-balance-week-1",
    "unit-unpaid-balance-week-2",
    "unit-unpaid-balance-week-3",
    "unit-unpaid-balance-week-4",
    "unit-unpaid-balance-week-5",
    "unit-unpaid-balance-week-6",
    "unit-unpaid-balance-week-7",
    "unit-unpaid-balance-week-8",
    "unit-unpaid-balance-week-9",
    "unit-unpaid-balance-week-10",
    "unit-unpaid-balance-week-11",
    "unit-unpaid-balance-week-12",
    "unit-unpaid-balance-week-13",
    "unit-unpaid-balance-week-14",
])

export const emailTemplateSchema = z.object({
    subject: mediumString,
    body: shortMarkdownString,
    kind: emailTemplateKindSchema,
})
export type EmailTemplate = z.infer<typeof emailTemplateSchema>
