import { z } from "zod"
import { dineroStorableSchema } from "./Common"
import { aidString, uuidString } from "./BaseStrings"

export const otonomFileIdSchema = z.discriminatedUnion("kind", [
    z.object({
        kind: z.literal("units"),
    }),
    z.object({
        kind: z.literal("suppliers"),
        /** The UUID of the supplier. */
        supplierUUID: uuidString,
        /** The fiscal year of the unit or supplier. */
        fiscalYear: z.number(),
    }),
])
export type OtonomFileId = z.infer<typeof otonomFileIdSchema>

export const coreOtonomFileSchema = z.object({
    rows: z.array(
        z.object({
            aid: aidString,
            amount: dineroStorableSchema,
            date: z.number(),
            /** The ID of the transaction document in Firestore. */
            transactionId: z.string().min(1),
            /** A map from the IDs of the invoice transactions document this transaction is paying to the amount of that transaction that is being paid. */
            invoiceTransactions: z.record(dineroStorableSchema).optional(),
        })
    ),
    /** The expiration of the document as a Firestore timestamp. */
    expirationTimestamp: z.any(),
    expirationSeconds: z.number(),
    nTransactionsWithError: z.number(),
    locked: z.boolean().optional(),
})

export const preparedOtonomFileSchema = coreOtonomFileSchema.and(otonomFileIdSchema)
export type PreparedOtonomFile = z.infer<typeof preparedOtonomFileSchema>
