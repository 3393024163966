import { Country } from "../@constants/Country"

/** Applies the following transformations to a string:
 * - lowercase
 * - remove leading and trailing whitespace
 * - transforms all whitespace into '-'
 * - removes all diacritics (accents) and other odd characters (ligatures, exponents, variants of the same character, etc.)
 *
 * @param data - The data to standardize.
 */
function stdString(data: string) {
    return data
        .toLowerCase()
        .trim()
        .replace(/\s+/g, " ")
        .normalize("NFKD")
        .replace(/\p{Diacritic}/gu, "")
}

/** Given a country name or symbol, if it finds a match, returns a normalized form of the name.
 * If not found, returns the given string.
 *
 * @example
 * ```ts
 * normalizeCountryName("CaNada ") // "Canada"
 * normalizeCountryName("Unknown name") // "Unknown name"
 * normalizeCountryName("US") // "United States"
 * ```
 */
export function normalizeCountryName(name: string): { name: string; symbol: Country | null }
export function normalizeCountryName(name: undefined | null): null
export function normalizeCountryName(
    name: string | undefined | null
): { name: string; symbol: Country | null } | null
export function normalizeCountryName(
    name: string | undefined | null
): { name: string; symbol: Country | null } | null {
    if (name === undefined || name === null) return null
    switch (stdString(name)) {
        case "canada":
        case "ca":
            return { name: "Canada", symbol: Country.Canada }
        case "united states":
        case "united states of america":
        case "us":
        case "usa":
            return { name: "United States", symbol: Country.UnitedStates }
        case "france":
        case "fr":
            return { name: "France", symbol: Country.France }
        default:
            return { name, symbol: null }
    }
}

/** Given a state name, if it finds a match, returns a normalized form of the name.
 * If not found, returns the given string.
 *
 * @example
 * ```ts
 * normalizeStateName("Quebec") // "Quebec"
 * normalizeStateName("Quebec ") // "Quebec"
 * normalizeStateName("Unknown name") // "Unknown name"
 * normalizeStateName("QC") // "Quebec"
 * ```
 */
export function normalizeStateName(name: string): string
export function normalizeStateName(name: undefined | null): null
export function normalizeStateName(name: string | undefined | null): string | null
export function normalizeStateName(name: string | undefined | null) {
    if (!name) return name ?? null
    switch (stdString(name)) {
        case "quebec":
        case "qc":
            return "QC"
        case "ontario":
        case "on":
            return "ON"
        case "alberta":
        case "ab":
            return "AB"
        case "british columbia":
        case "bc":
            return "BC"
        case "manitoba":
        case "mb":
            return "MB"
        case "new brunswick":
        case "nb":
            return "NB"
        case "newfoundland and labrador":
        case "nl":
            return "NL"
        case "nova scotia":
        case "ns":
            return "NS"
        case "prince edward island":
        case "pei":
            return "PE"
        case "saskatchewan":
        case "sk":
            return "SK"
        case "northwest territories":
        case "nt":
            return "NT"
        case "nunavut":
        case "nu":
            return "NU"
        case "yukon":
        case "yt":
            return "YT"
        default:
            return name
    }
}
