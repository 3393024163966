import about from "./about"
import communication from "./communication"
import core from "./core"
import errors from "./errors"
import form from "./form"
import messages from "./messages"
import report from "./report"

export default {
    about,
    communication,
    core,
    errors,
    form,
    messages,
    report,
}
