import { ResponseForCode } from "server/pathTypes"
import { SimpleTKey } from "./translate"

type Errors = ResponseForCode<400>["response"]["application/json"]

/** Helper type to check for the presence of a 'localizedMessage' property. */
type HasLocalizedMessage<T> = T extends { localizedMessage: string } ? T : never

/** Type that includes all types without a 'localizedMessage' property. */
type ErrorsWithoutLocalizedMessage = Exclude<Errors, HasLocalizedMessage<Errors>>["name"]

const backendCodesMapping: Record<ErrorsWithoutLocalizedMessage, SimpleTKey> = {
    "request-closed": "request_closed",
    "aid-invalid-format": "invalid_aid_format",
    "aid-already-in-use": "aid_already_used_by_another_account",
    "unknown-error": "unknown_error_occurred",
    "object-already-exists": "object_already_exists",
    "field-too-long": "field_DETAILS_too_long",
    "auth-issue":
        "auth_issue_try_logging_out_and_logging_back_in_or_make_sure_you_have_adequate_permissions",
    "building-invalid": "building_not_found",
    "https-only": "ensure_https_is_enabled",
    unimplemented: "unimplemented",
    "object-invalid": "object_invalid",
    "object-not-found": "object_not_found",
    "object-archived": "object_archived",
    "person-cannot-be-archived-as-still-associated-to-a-unit":
        "person_cannot_be_archived_as_still_associated_to_a_unit",
    "locker-cannot-be-archived-as-still-associated-to-a-unit":
        "locker_cannot_be_archived_as_still_associated_to_a_unit",
    "parking-cannot-be-archived-as-still-associated-to-a-unit":
        "parking_cannot_be_archived_as_still_associated_to_a_unit",
    "maximum-number-of-unpaid-balances-templates-is-4":
        "maximum_number_of_unpaid_balances_templates_is_4",
    "invalid-post-author": "invalid_post_author",
    "deleting-post-posted-by-other-user": "deleting_post_posted_by_other_user",
    "default-penalty-cannot-be-created": "default_penalty_cannot_be_created",
    "default-penalty-cannot-be-deleted": "default_penalty_cannot_be_deleted",
    "other-unit-already-has-locker": "other_unit_already_has_locker",
    "other-unit-already-has-parking": "other_unit_already_has_parking",
    "adding-to-archived-year": "cannot_add_to_archived_year",
    "transaction-of-wrong-type-cannot-be-approved": "transaction_of_wrong_type_cannot_be_approved",
    "archived-fiscal-year": "archived_fiscal_year",
    "transaction-already-paid-cannot-be-cancelled": "transaction_already_paid_cannot_be_cancelled",
    "transaction-with-no-banks-cannot-be-reconciled":
        "transaction_with_no_banks_cannot_be_reconciled",
    "not-reconciled-as-amounts-do-not-match": "not_reconciled_as_amounts_do_not_match",
}

/** An object mapping error codes to translation keys.  */
export const errorCodesMapping: { [key in string]: SimpleTKey | undefined } = {
    "pending-transaction": "pending_transaction",
    "new-year-not-after-most-recent-year": "new_year_not_after_most_recent_year",
    "invalid-role": "an_invalid_role_was_assigned_to_this_user",
    "error-adding-user": "unknown_error_occurred",
    "functions/unauthenticated":
        "auth_issue_try_logging_out_and_logging_back_in_or_make_sure_you_have_adequate_permissions",
    "unit-not-found": "unit_not_found",
    unable_to_add_reverse_check: "unable_to_add_reverse_check",
    "unable_to_add_reverse_wire-transfer": "unable_to_add_reverse_wire-transfer",
    "unit-cannot-be-changed": "this_unit_can_no_longer_be_changed_as_it_is_archived",
    "balance-not-null": "unit_balance_not_null_please_select_way_to_manage_balance",
    "unit-has-too-many-lockers":
        "unit_has_over_30_lockers_which_is_prohibited_so_edits_were_not_saved",
    "unit-has-too-many-parkings":
        "unit_has_over_30_parkings_which_is_prohibited_so_edits_were_not_saved",
    "other-unit-has-locker-already":
        "another_unit_already_has_one_of_the_lockers_so_edits_were_not_saved",
    "other-unit-has-parking-already":
        "another_unit_already_has_one_of_the_parkings_so_edits_were_not_saved",
    "transaction-not-found": "transaction_no_longer_exists",
    "bank-not-found": "bank_not_found",
    "bank-already-exists": "bank_not_created_as_it_already_exists",
    "unit-already-exists": "unit_not_created_as_it_already_exists",
    "bank-invalid": "bank_not_saved_as_it_is_invalid",
    "unit-invalid": "unit_not_saved_as_it_is_invalid",
    "transaction-already-paid": "transaction_already_paid_cannot_be_changed",
    "sum-not-100": "vote_share_total_is_not_100",
    "negative-vote-share": "one_of_the_vote_shares_is_negative_which_is_prohibited",
    "institutionNumber-length-should-be-3": "institution_number_should_be_3_digits",
    "transitNumber-length-should-be-5": "transit_number_should_be_5_digits",
    "accountNumber-length-should-be-5-to-20": "account_number_should_be_5_to_20_digits",
    "encrypt-error": "encrypt_error",
    "decrypt-error": "decrypt_error",
    "person-still-associated-to-a-unit": "person_is_still_an_owner_or_resident_of_a_unit",
    "duplicate-associatedBill": "transaction_had_duplicate_associated_bill",
    "no-transactions-to-send": "no_transactions_to_send_to_otonom",
    "could-not-fetch-otonom-token":
        "error_connecting_to_otonom_ensure_payee_details_are_saved_with_them",
    "difference-not-zero": "month_not_reconciled_as_difference_not_zero",
    "check-already-exists": "check_already_exists",
    "supplier-has-no-otonom-id": "supplier_has_no_otonom_id",
    "invalid-otonom-id-for-every-account": "invalid_otonom_id_for_every_account",
    "missing-bank-account-information-for-accounts":
        "missing_bank_account_information_for_accounts",
    "no-account-has-information": "no_account_has_information",
    "submission-to-otonom-failed": "submission_to_otonom_failed",
    "could-not-find-otonom-file": "otonom_file_not_found",
    "lock-already-in-place": "lock_already_in_place",
    "unable-to-place-lock": "unable_to_place_lock",
    "otonom-file-not-found": "otonom_file_not_found",
    "auth/invalid-email": "invalid_email_please_try_again",
    "auth/invalid-credentials": "invalid_password_please_try_again",
    "auth/invalid-credential": "invalid_password_please_try_again",
    "auth/user-not-found": "no_corresponding_user_with_this_email_found",
    "auth/weak-password": "invalid_password_please_try_again",
    "auth/wrong-password": "the_entered_password_is_not_correct",
    "auth/too-many-requests":
        "too_many_requests_were_recently_made_for_this_account_please_wait_a_bit_before_trying_again",
    "auth/user-disabled": "account_disabled",
    "auth/unverified-email": "please_confirm_your_email_before_logging_in",
    "auth/network-request-failed": "not_connected_to_internet",
    not_connected_to_internet: "not_connected_to_internet",
    "user-banned": "cannot_create_posts_as_you_are_banned",
    ...backendCodesMapping,
}
