import { useEffect, useRef } from "react"
import isEqual from "lodash/isEqual"

function useDeepCompareMemoize(value: any) {
    const ref = useRef()
    if (!isEqual(value, ref.current)) {
        ref.current = value
    }
    return ref.current
}

/** A wrapped useEffect function that uses a deep comparison on objects to see if they changed. */
export function useDeepCompareEffect(
    callback: Parameters<typeof useEffect>[0],
    dependencies: any[]
) {
    useEffect(callback, dependencies.map(useDeepCompareMemoize))
}
