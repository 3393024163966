import React from "react"
import { Modal } from "@mantine/core"
import { useAppTranslation } from "hooks/hooks"
import { auth } from "firebaseSetup"
import { sendPasswordResetEmail } from "firebase/auth"
import { Button } from "@mantine/core"
import { WTextInput } from "components/Inputs/WTextInput"
import { useForm } from "hooks/useForm"
import { z } from "zod"
import { useDisclosure } from "@mantine/hooks"
import {
    setLoadingState,
    showErrorNotification,
    showSuccessNotification,
} from "logic/notifications"

const resetPasswordFormSchema = z.object({
    email: z.string().email(),
})

export default function ResetPasswordPopup() {
    const t = useAppTranslation()
    const [show, { open, close }] = useDisclosure(false)

    function onSubmit(data: z.infer<typeof resetPasswordFormSchema>) {
        close()
        setLoadingState({ tkey: "messages:sending_reset_link" })
        sendPasswordResetEmail(auth, data.email)
            .then(() => {
                showSuccessNotification({
                    message: "click_link_to_reset_password",
                    autoClose: false,
                })
                setLoadingState(false)
            })
            .catch((error) => {
                showErrorNotification({ message: "unable_to_send_email" }, error)
                setLoadingState(false)
            })
    }

    const form = useForm(resetPasswordFormSchema, onSubmit)

    return (
        <>
            <Button
                variant="transparent"
                fullWidth
                component="button"
                size="xs"
                onClick={open}
                color="gray"
            >
                {t("messages:forgot_password")}
            </Button>

            <Modal opened={show} onClose={close} title={t("messages:reset_password")}>
                <WTextInput
                    label="core:email"
                    editing
                    variant="default"
                    {...form.propsForWTextInput("email")}
                />

                <Button mt="md" fullWidth onClick={form.submit}>
                    {t("messages:send_link_to_reset_password")}
                </Button>
            </Modal>
        </>
    )
}
