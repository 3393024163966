const report = {
    appnflat_for_k: "AppNFlat.com - {{buildingName}}",
    appnflat_report: "AppNFlat.com Report",
    create_a_statement_for_2: "Create a statement for:",
    generating_statement: "Generating Statement...",
    page_on_k: "Page {{number}} on {{total}}",
    there_was_a_problem_while_generating_the_statement:
        "There was a problem while generating the statement.",
} as const

export default report
